<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 卡片视图 -->
    <!-- <el-card> -->
    <!-- 搜索-->
    <el-form ref="form" label-width="80px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="电子围栏" prop="isForceUpdate">
            <el-select
              v-model="queryInfo.condition.electronicFenceCode"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in electronicFenceList"
                :key="item.id"
                :label="item.electronicFenceName"
                :value="item.electronicFenceCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="订单状态" prop="isForceUpdate">
            <el-select
              v-model="queryInfo.condition.orderStatus"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备编号" prop="topic">
            <el-input
              v-model="queryInfo.condition.deviceId"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="锁编号" prop="topic">
            <el-input
              v-model="queryInfo.condition.deviceNum"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="手机号" prop="topic">
            <el-input
              v-model="queryInfo.condition.phone"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="18" align="right">
          <el-button type="primary" plain @click="reset">重置</el-button>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- <el-row :gutter="20" type="flex" justify="space-between">
      <el-col style="width: 350px">
        <div class="flex align-center justify-end header-search-box">
          <el-input
            placeholder="请输入手机号查询"
            clearable
            v-model="searchInput"
            class="header-search-input ml10"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              class="textblue searchBtn"
              @click="search()"
            ></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row> -->
    <!-- 用户列表区域 -->
    <el-table
      :data="tableData"
      fit
      class="heightfix"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column label="编号" type="index" width="50"> </el-table-column>
      <el-table-column label="订单号" align="center" prop="memberName">
        <template slot-scope="scope">
          <span>{{ scope.row.orderNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备编号" align="center" prop="memberName">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="锁编号" align="center" prop="memberName">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceNum }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="订单开始时间"
        align="center"
        width="140px"
        prop="memberName"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.createDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付金额" align="center" prop="memberName">
        <template slot-scope="scope">
          <span>{{ scope.row.orderPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="使用人头像" align="center" prop="memberName">
        <template slot-scope="scope">
          <el-avatar
            :src="scope.row.memberLogo"
            shape="square"
            :size="60"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="使用人昵称" align="center" prop="memberName">
        <template slot-scope="scope">
          <span v-if="scope.row.orderStatus == 0">---</span>
          <span v-else>{{ scope.row.memberName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户手机号" align="center" prop="memberName">
        <template slot-scope="scope">
          <span v-if="scope.row.orderStatus == 0">---</span>
          <span v-else>{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属电子围栏" align="center" prop="memberName">
        <template slot-scope="scope">
          <span>{{ scope.row.electronicFenceName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="管理员" align="center" prop="memberCode">
        <template slot-scope="scope">
          <span>{{ scope.row.adminName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="" align="center" prop="id" v-if="false">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="付款状态" align="center" prop="memberCode">
        <template slot-scope="scope">
          <span>{{ judgeStatus(scope.row.orderStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="订单结束时间"
        align="center"
        width="140px"
        prop="orderPrice"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.orderStatus == 0">---</span>
          <span v-else>{{ scope.row.orderOverTime }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
        width="200px"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="gotoDetail(scope.row.id, scope.row.userCode)"
            style="padding: 10px;"
            >详情</el-button
          >
          <el-button
            type="text"
            slot="reference"
            style="padding: 10px;"
            @click="toEdit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.orderStatus == 2 || scope.row.orderStatus == 1"
            @click="addCompensate(scope.row)"
            style="padding: 10px;"
            >添加赔偿</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footerBox">
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.currPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      title="添加赔偿"
      :visible.sync="compensateDialogVisible"
      width="30%"
    >
      <div>
        <el-row>
          <el-form
            label-width="80px"
            ref="compensateInfo"
            :model="compensateInfo"
            :rules="compensateRules"
          >
            <el-col :span="24">
              <el-form-item label="损坏赔偿">
                <el-select
                  v-model="compensateInfo.compensationCodeList"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in lossTermOptions"
                    :key="item.id"
                    :label="
                      item.compensationPart +
                        '(' +
                        item.compensationAmount +
                        '一' +
                        item.unitPart +
                        ')'
                    "
                    :value="item.compensationCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="遗失赔偿">
                <el-input
                  v-model="compensateInfo.lostFee"
                  placeholder="请输入"
                  type="number"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="compensateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toAddCompensate()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改订单"
      :visible.sync="modifyOrderDialogVisible"
      width="30%"
    >
      <div>
        <el-row>
          <el-form
            label-width="100px"
            ref="modifyOrderInfo"
            :model="modifyOrderInfo"
          >
            <el-col :span="24">
              <el-form-item label="订单状态" prop="orderStatus">
                <!-- <el-input v-model="modifyOrderInfo.orderStatus" placeholder="请输入" type="number"></el-input> -->
                <el-select
                  v-model="modifyOrderInfo.orderStatus"
                  placeholder="请选择"
                >
                  <el-option label="未还车" :value="1"></el-option>
                  <el-option label="未支付" :value="2"></el-option>
                  <el-option label="已支付" :value="3"></el-option>
                  <el-option label="系统免单" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="订单金额" prop="orderPrice">
                <el-input
                  v-model="modifyOrderInfo.orderPrice"
                  placeholder="请输入"
                  type="number"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="订单结束时间" prop="orderOverTime">
                <el-date-picker
                  v-model="modifyOrderInfo.orderOverTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  style="width: 100%;"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modifyOrderDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toModifyOrder()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- </el-card> -->
  </div>
</template>

<script>
export default {
  data() {
    let damageFee = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入损失赔偿"));
      } else if (value <= 0) {
        callback(new Error("赔偿不能小于0"));
      } else {
        callback();
      }
    };
    let lostFee = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入遗失赔偿"));
      } else if (value <= 0) {
        callback(new Error("赔偿不能小于0"));
      } else {
        callback();
      }
    };
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      tableData: [],
      loading: true,
      id: "",
      searchInput: "",
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          orderStatus: null,
          phone: ""
        }
      },
      userlist: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      statusOptions: [
        {
          value: 1,
          label: "未还车"
        },
        {
          value: 2,
          label: "未支付"
        },
        {
          value: 3,
          label: "已支付"
        }
      ],
      compensateInfo: {
        orderNumber: "",
        compensationCodeList: [], //损坏赔偿
        lostFee: "" //遗失赔偿
      },
      compensateRules: {
        damageFee: [
          { required: true, validator: damageFee, trdeviceIdigger: "blur" }
        ],
        lostFee: [{ required: true, validator: lostFee, trigger: "blur" }]
      },
      compensateDialogVisible: false,
      electronicFenceList: [], //电子围栏列表
      modifyOrderInfo: {
        id: "",
        orderStatus: "",
        orderPrice: "",
        orderOverTime: ""
      },
      modifyOrderDialogVisible: false,
      lossTermOptions: [],
      deviceId: ""
    };
  },
  created() {
    // this.queryInfo.page=this.$route.query.page;
    //  this.queryInfo.limit=this.$route.query.limit;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
    this.getElectronicFenceList();
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //损失项
    getLossTerm() {
      this.$http.post("/compensation/queryList", {}).then(res => {
        if (res.data.code === 200) {
          this.lossTermOptions = res.data.data;
        }
      });
    },
    //修改订单
    toModifyOrder() {
      this.$http
        .post("/memberOrder/updatePc", this.modifyOrderInfo)
        .then(res => {
          if (res.data.code === 200) {
            this.$notify.success({
              title: "提示",
              message: "修改成功",
              showClose: true
            });
            this.modifyOrderDialogVisible = false;
            this.getListData();

            if (this.modifyOrderInfo.orderStatus == "4") {
              this.$http.post("/device/updateDeviceStatus", {
                deviceId: this.deviceId
              });
            } else if (this.modifyOrderInfo.orderStatus == "3") {
              this.$http.post("/device/updateDeviceStatus", {
                deviceId: this.deviceId
              });
            }
          }
        });
    },
    //查询电子围栏
    getElectronicFenceList() {
      const data = {};
      this.$http.post("/electronicFence/queryList", data).then(res => {
        if (res.data.code == 200) {
          this.electronicFenceList = res.data.data;
        }
      });
    },
    //条件查询
    search() {
      // this.queryInfo.condition.phone = this.searchInput;
      this.getListData();
    },

    //重置
    reset() {
      this.queryInfo.condition = {};
      this.getListData();
    },

    //状态改变事件
    // changeStatus(row) {

    //   var that=this;
    // //   alert()
    //  that.repairsStatus=1;
    //   that.$http.post("/repairsInfo/save", {repairsStatus:1,id:row.id}
    //   ).then(function (response) {
    //       that.$notify.success({
    //         title: "提示",
    //         message: "保存成功",
    //         showClose: true,
    //       });
    //     that.getListData();
    //   }).catch(function(error){
    //     //   console.log("---")
    //       that.$notify.info({
    //         title: "提示",
    //         message: "保存失败",
    //         showClose: true,
    //       });
    //   })

    // },
    judgeStatus(orderStatus) {
      if (orderStatus === 1) {
        return "未还车";
      } else if (orderStatus === 2) {
        return "未支付";
      } else if (orderStatus === 3) {
        return "已支付";
      } else if (orderStatus === 4) {
        return "系统免单";
      } else {
        return "";
      }
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/financeMag/details",
        query: {
          id: id,
          page: that.queryInfo.page,
          limit: that.queryInfo.limit
        }
      });
    },

    //修改订单状态
    toEdit(row) {
      this.modifyOrderDialogVisible = true;
      this.modifyOrderInfo.id = row.id;
      this.modifyOrderInfo.orderStatus = row.orderStatus;
      this.modifyOrderInfo.orderPrice = row.orderPrice;
      this.modifyOrderInfo.orderOverTime = row.orderOverTime;
      this.deviceId = row.deviceId;
    },

    //添加赔偿项
    addCompensate(row) {
      this.compensateInfo.orderNumber = row.orderNumber;
      this.compensateInfo.compensationCodeList = row.compensationCodeList;
      this.compensateInfo.lostFee = row.lostFee;
      this.compensateDialogVisible = true;
      this.getLossTerm();
    },

    toAddCompensate() {
      this.$http
        .post(
          "/memberOrderExtraExpense/saveMemberOrderExtraExpense",
          this.compensateInfo
        )
        .then(res => {
          if (res.data.code === 200) {
            this.compensateDialogVisible = false;
            this.$notify.success({
              title: "提示",
              message: "修改成功",
              showClose: true
            });
            this.getListData();
          }
        });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/repairsInfo/delete", { id: id })
          .then(function(response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true
            });
            that.getListData();
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      var data = that.queryInfo;
      //   console.log(data)
      that.$http
        .post("/memberOrder/list3", {
          currPage: data.currPage,
          pageSize: data.pageSize,
          condition: data.condition
        })
        .then(function(response) {
          //   console.log("------")
          // console.log(response);
          if (response.data.code == 200) {
            // console.log("------")
            // console.log(response.data.data.data)
            that.total = response.data.data.count;
            //   that.queryInfo.page=response.data.page
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-table::before {
  height: 0;
}

.textblue {
  color: #008eff;
}

.managingPeople {
  .el-card {
    height: calc(100vh - 64px);
    position: relative;

    .el-select > .el-input {
      width: 200px;
    }

    input::placeholder {
      font-size: 12px;
    }

    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }

    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}

.heightfix {
  overflow-y: scroll;
  max-height: 650px;
}

/deep/ .el-col-12 {
  text-align: right;
}

/deep/ .el-select {
  width: 100%;
}

/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
